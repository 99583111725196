














import CodeBlock from '@/components/code-blocks/CodeBlock.vue';
import { getGameProviderAccount } from '@/api/GameProvider';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  name: 'SuperAdminGameProviderReportingApi',
  components: {
    CodeBlock
  },
  props: {
    accountId: {
      type: Number,
      required: true
    }
  },
  data(): Record<string, unknown> {
    return {
      isGameProviderAccountLoading: true,
      gameProviderAccount: {}
    };
  },
  computed: {
    apiKey(): string | null {
      return this.gameProviderAccount?.apiKey ?? null;
    }
  },
  watch: {
    accountId: {
      handler(): void {
        this.loadGameProviderAccount();
      },
      immediate: true
    }
  },
  methods: {
    async loadGameProviderAccount(): Promise<void> {
      try {
        this.isGameProviderAccountLoading = true;
        this.gameProviderAccount = await getGameProviderAccount(
          this.accountId
        );
        this.isGameProviderAccountLoading = false;
      } catch (error) {
        errorToastMessage(error);
      }
    }
  }
};
