






































































import CodeBlock from '@/components/code-blocks/CodeBlock.vue';
import VStatus from '@/components/VStatus.vue';

const formatObject = (object: Record<string, unknown>): string => {
  return JSON.stringify(object, null, 4);
};

export default {
  name: 'SuperAdminGameProviderReportingApi',
  components: {
    CodeBlock,
    VStatus
  },
  data(): Record<string, unknown> {
    return {
      endpoints: [
        {
          url: 'https://integration.trueplay.io/api/v1/game-provider-integration/transaction',
          method: 'GET',
          name: 'Transactions List',
          description: `Search game-provider's copy-stake transactions`,
          parameters: {
            query: [
              { name: 'from', type: 'String', required: false },
              { name: 'to', type: 'String', required: false },
              { name: 'operatorId', type: 'Integer', required: false },
              { name: 'userWalletHash', type: 'String', required: false },
              {
                name: 'copiedFromWalletHash',
                type: 'String',
                required: false
              },
              { name: 'type', type: 'String', required: false },
              { name: 'gameName', type: 'String', required: false },
              { name: 'size', type: 'Integer', required: false }
            ],
            header: [
              {
                name: 'X-GAME-PROVIDER-API-KEY',
                type: 'String',
                required: true
              }
            ]
          },
          responses: [
            {
              statusCode: 200,
              status: 'OK',
              description: 'Request is successful',
              content: formatObject({
                transactions: [
                  {
                    date: 'string',
                    sortId: 'string',
                    id: 'string',
                    createdAt: 'string',
                    userWalletHash: 'string',
                    operatorId: 'string',
                    operatorName: 'string',
                    gameProvider: 'string',
                    gameName: 'string',
                    type: 'string',
                    attributes: {
                      additionalProp1: 'string',
                      additionalProp2: 'string',
                      additionalProp3: 'string'
                    }
                  }
                ],
                hasNext: true
              })
            },
            {
              statusCode: 400,
              status: 'Bad Request',
              description: 'Invalid request parameters provided',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 403,
              status: 'Forbidden',
              description: 'Forbidden',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 404,
              status: 'Not Found',
              description: 'Not Found',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 406,
              status: 'Not Acceptable',
              description: 'Not Acceptable',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 502,
              status: 'Bad Gateway',
              description: 'Bad Gateway',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 503,
              status: 'Service Unavailable',
              description: 'Service Unavailable',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            }
          ]
        },
        {
          url: 'https://integration.trueplay.io/api/v1/game-provider-integration/transaction/csv',
          method: 'GET',
          name: 'Download Transactions List',
          description: `Download game-provider's copy-stake transactions`,
          parameters: {
            query: [
              { name: 'from', type: 'String', required: false },
              { name: 'to', type: 'String', required: false },
              { name: 'operatorId', type: 'Integer', required: false },
              { name: 'userWalletHash', type: 'String', required: false },
              {
                name: 'copiedFromWalletHash',
                type: 'String',
                required: false
              },
              { name: 'type', type: 'String', required: false },
              { name: 'gameName', type: 'String', required: false }
            ],
            header: [
              {
                name: 'X-GAME-PROVIDER-API-KEY',
                type: 'String',
                required: true
              }
            ]
          },
          responses: [
            {
              statusCode: 200,
              status: 'OK',
              description: 'Request is successful',
              content: 'string'
            },
            {
              statusCode: 400,
              status: 'Bad Request',
              description: 'Invalid request parameters provided',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 403,
              status: 'Forbidden',
              description: 'Forbidden',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 404,
              status: 'Not Found',
              description: 'Not Found',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 406,
              status: 'Not Acceptable',
              description: 'Not Acceptable',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 502,
              status: 'Bad Gateway',
              description: 'Bad Gateway',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 503,
              status: 'Service Unavailable',
              description: 'Service Unavailable',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            }
          ]
        },
        {
          url: 'https://integration.trueplay.io/api/v1/game-provider-integration/info',
          method: 'GET',
          name: 'Game Provider Info',
          description: `Get game provider info`,
          parameters: {
            query: [],
            header: [
              {
                name: 'X-GAME-PROVIDER-API-KEY',
                type: 'String',
                required: true
              }
            ]
          },
          responses: [
            {
              statusCode: 200,
              status: 'OK',
              description: 'Request is successful',
              content: formatObject({
                id: 1,
                email: 'email@email.com',
                gameProviderName: 'PGSOFT',
                apiKey: 'gfjvkb7676789',
                state: 'ACTIVE',
                companyName: 'Company, LLC',
                companyAddress: 'London',
                createdAt: '2023-12-29T10:51:24.362Z',
                updatedAt: '2023-12-29T10:51:24.362Z'
              })
            },
            {
              statusCode: 400,
              status: 'Bad Request',
              description: 'Invalid request parameters provided',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 403,
              status: 'Forbidden',
              description: 'Forbidden',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 404,
              status: 'Not Found',
              description: 'Not Found',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 406,
              status: 'Not Acceptable',
              description: 'Not Acceptable',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 502,
              status: 'Bad Gateway',
              description: 'Bad Gateway',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            },
            {
              statusCode: 503,
              status: 'Service Unavailable',
              description: 'Service Unavailable',
              content: formatObject({
                message: 'string',
                property: 'string'
              })
            }
          ]
        }
      ],
      methodsTypes: {
        GET: 'primary'
      }
    };
  },
  methods: {
    getMethodType(method: string): string {
      return this.methodsTypes[method] ?? 'default';
    },
    getStatusCodeColor(statusCode: number): string {
      if (statusCode >= 200 && statusCode < 300) {
        return '#6fcf97';
      }

      return '#ec5a58';
    }
  }
};
